import { initDatadog as initDatadogComponents } from '@sezane/front-components';
import env from './env';
export var initDatadog = function () {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }
    initDatadogComponents({
        clientToken: 'pub00cde87a1d48829c3afe2da3bcbf745e',
        applicationId: 'a0ea0f08-2a45-4aff-92f0-909c9a479f9b',
        site: 'datadoghq.eu',
        service: 'front',
        env: env,
        version: process.env.COMMIT_HASH,
        sessionSampleRate: 3,
        sessionReplaySampleRate: 20,
        traceSampleRate: 100,
        traceContextInjection: 'all',
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        trackSessionAcrossSubdomains: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: ['https://api.sezane.com', 'https://api.octobre-editions.com'],
    });
};
